<template>
  <section class="page-agence">
    <!-- HEADER -->
    <a class="firstWidget" style="position: fixed; top: 42vh; right: 0vw; z-index: 10;" v-if="immodvisor.id && immodvisor.widgetKey" v-immodvisor-widget id="imdw-footer" :data-cid="immodvisor.id" :data-hash="immodvisor.widgetKey" data-ctype="company" data-theme="dark" data-background="white" data-enable="true"></a>
    <section class="hero-small">
      <div>
        <h1>
          Votre agence Maisons Alysia <br/>{{ agency ? agency.name : '' }}
        </h1>
      </div>
    </section>
    <app-spinner v-if="loading"></app-spinner>
    <app-error v-else-if="errors.length" title="Erreur" :messages="errors"></app-error>
    <div class="grid-container" v-else-if="agency">
      <!-- CARTE AGENCE -->
      <card-type-img :title="`Votre constructeur à ${agency.address.city}`" v-if="members.length">
        <template v-slot:content>
          <div class="grid-x grid-margin-x align-center-middle">
            <template v-for="member in members">
              <img v-if="member.photo" class="cell member-media small-order-2 medium-order-1" :key="member.memberId" :src="member.photo" />
              <avatar v-else class="cell member-media small-order-2 medium-order-1" :key="member.memberId"/>
            </template>
            <img v-if="mapMedia" :src="mapMedia.url" class="cell map-media small-order-1 medium-order-2"/>
          </div>
          <p>{{ agency.description }}</p>
        </template>
        <template v-slot:img>
          <img :src="outsideMedias[1] ? outsideMedias[1].url : require('@/assets/img/pages/alysia-exterieur.png')" alt="Maisons alysia" />
        </template>
      </card-type-img>
      <!-- MAP -->
      <div class="agency-contact-container">
        <div class="agency-map-container">
          <AgencyMap :address="agency.address"/>
        </div>
        <div class="agency-contact">
          <h2>{{ agency.name }}</h2>
          <p>
            {{ agency.address.addressLine1 }}<br/>
            {{ agency.address.addressLine2 }}<br v-if="agency.address.addressLine2" />
            {{ agency.address.postalCode }} {{ agency.address.city }}<br/>
          </p>
          <a class="phone" :href="`tel:${agency.phone}`" v-if="showPhone">
            {{ agency.phone }}
          </a>
          <app-button class="phone btn-agency-see-phone" v-else @click="showPhone = true" size="small">Voir le numéro</app-button>
          <strong>{{ agency.openingHours }}</strong>
          <app-button hasArrow @click="scrollToContactForm">Nous écrire</app-button>
          <router-link :to="`/agences/${department}`">Voir toutes les agences de <span class="department-name">{{ department }}</span></router-link>
        </div>
      </div>

      <div class="show-room" v-if="insideMedias.length">
        <h2>Le show-room</h2>
        <div class="grid-x grid-margin-x align-center grid-margin-y">
          <img v-for="media in insideMedias" class="cell medium-6 small-12" :key="media.agencyMediaId" :src="media.url" />
        </div>
      </div>
    </div>
    <!-- IMMODVISER (certificat fixe + avis) -->
    <div class="grid-container">
      <div v-if="immodvisor.id && immodvisor.widgetKey" v-immodvisor-widget id="imdw-page" :data-cid="immodvisor.id" :data-hash="immodvisor.widgetKey" data-ctype="company" data-margin="true" data-width="100%" data-number-reviews="5" data-wording=”plural”></div>
    </div>
    <!-- CONTACT FORM -->
    <form v-if="agency" ref="contactForm" @submit.prevent="contactAgency" id="agency-contact-form">
      <div class="grid-container">
        <h2>Nous écrire</h2>
        <div class="grid-x grid-margin-x grid-margin-y align-middle">
          <div class="cell medium-6">
            <app-input required v-model="contact.name" label="Votre nom" placeholder=""></app-input>
          </div>
          <div class="cell medium-6">
            <app-input required v-model="contact.firstname" label="Votre prénom" placeholder=""></app-input>
          </div>
          <div class="cell medium-8">
            <app-input required v-model="contact.email" label="Votre email" type="email" placeholder=""></app-input>
          </div>
          <div class="cell medium-4">
            <app-input required v-model="contact.phone" label="Votre numéro de téléphone" type="text" placeholder=""></app-input>
          </div>
          <div class="cell medium-12">
            <app-input required v-model="contact.subject" label="Objet" placeholder=""></app-input>
          </div>
          <div class="cell medium-12">
            <app-textarea class="cell small-12" required v-model="contact.message" label="Votre message *" placeholder=""></app-textarea>
          </div>
          <div class="cell auto">
            <app-checkbox value="true" :input="contact.agreement" :required="true" name="agree">J'accepte d'être recontacté *</app-checkbox>
          </div>
          <div class="cell medium-shrink">
            <app-button  class="cell shrink" type="submit">Envoyer</app-button>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import agencyApi from '@/services/api/agency';
import AgencyJobLabel from '@/services/enums/agency-job-label.enum';

import CardTypeImg from '@/components/general/CardTypeImg.vue';
import AgencyMap from '@/components/general/AgencyMap.vue';

import avatar from '@/assets/img/avatar.svg?inline';

export default {
  name: 'page-agence',
  components: {
    CardTypeImg,
    AgencyMap,
    avatar,
  },
  data() {
    return {
      loading: true,
      agency: null,
      medias: [],
      members: [],
      postalCode: null,
      contact: {
        name: null,
        firstname: null,
        email: null,
        phone: null,
        subject: null,
        message: null,
        agreement: null,
      },
      errors: [],
      department: this.getDepartment(),
      mediasLoaded: false,
      defaultPicture: avatar,
      AgencyJobLabel,

      showPhone: false,
    };
  },
  watch: {
    mediasLoaded() {
      if (this.outsideMedias.length) {
        const heroURL = this.outsideMedias[0].url;
        document.querySelector('.hero-small').style.backgroundImage = `linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(${heroURL})`;
      } else if (this.mediasLoaded) {
        document.querySelector('.hero-small').classList.add('default-background'); // permet d'afficher l'image par défaut
      }
    },
  },
  computed: {
    outsideMedias() {
      const outsides = this.medias.filter((media) => media.type === 'OUTSIDE');
      return outsides;
    },
    insideMedias() {
      return this.medias.filter((media) => media.type === 'INSIDE');
    },
    mapMedia() {
      const maps = this.medias.filter((media) => media.type === 'MAP');
      return maps[0] || null;
    },
    immodvisor() {
      if (!this.agency?.immodvisorKey) {
        return {
          id: null,
          widgetKey: null,
        };
      }
      const [id, widgetKey] = this.agency?.immodvisorKey?.split(':');
      return {
        id,
        widgetKey,
      };
    },
  },
  async created() {
    this.loading = true;
    try {
      await this.getAgency();
      await Promise.all([this.getMembers(), this.getMedias()]);
    } catch (error) {
      this.errors.push('L\'agence n\'est pas reconnue par nos services');
    }
    this.loading = false;
    this.getDepartment();
  },
  methods: {
    getDepartment() {
      const routeSplit = this.$route.path.split('/');
      return routeSplit[routeSplit.length - 2];
    },
    async getAgency() {
      try {
        const response = await agencyApi.getBySlugName(this.$route.params.slugName);
        this.agency = response;
      } catch (error) {
        throw error;
      }
    },
    async getMembers() {
      try {
        const response = await agencyApi.getAgencyMembers(this.agency.agencyId);
        this.members = response;
      } catch (error) {
        throw error;
      }
    },
    async getMedias() {
      try {
        const response = await agencyApi.getAgencyMedias(this.agency.agencyId);
        this.medias = response;
        this.mediasLoaded = true;
      } catch (error) {
        throw error;
      }
    },
    async contactAgency() {
      if (!this.$refs.contactForm.checkValidity()) {
        return;
      }
      try {
        await agencyApi.sendContact(this.agency.agencyId, this.contact);

        this.$analyticsModal.show({
          title: 'Merci',
          text: 'Votre demande a bien été envoyée',
          hasConfirm: true,
          hasCancel: true,
          confirmText: 'Fermer',
          cancelText: 'Retour à l\'accueil',
          queryName: 'remerciement-agence',
          onCancel: async () => {
            await this.$router.push({ name: 'home' }).catch(() => true);
          },
        });
      } catch (error) {
        throw error;
      }
    },
    scrollToContactForm() {
      const form = document.getElementById('agency-contact-form');
      form.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
  },
};

</script>

<style lang="sass">
.page-agence
  background: $medium-bg
  overflow-x: hidden
  .hero-small
    @include hero-small
    background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%)
    &.default-background
      background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/agency-hero.png)

  .card-type-img
    margin: 3rem auto
    .content
      svg
        background: $line
        width: 116px
        height: 106px
        padding: 10px 5px
        fill: $off-white
        border-radius: $global-border-radius

      img
        object-fit: cover
        border-radius: $global-border-radius
        &.member-media
          height: 126px
          width: 116px
        &.map-media
          width: 207px
          height: 207px
      @include breakpoint(small down)
        text-align: center
        .grid-x
          img, svg
            margin-bottom: 1rem

  .agency-contact-container
    display: flex
    border-radius: $global-border-radius
    margin: 2rem 0
    align-items: stretch
    height: 450px
    .agency-map-container
      width: 60%
      height: 100%
      border-radius: $global-border-radius 0 0 $global-border-radius
    .agency-contact
      width: 40%
      display: flex
      flex-direction: column
      justify-content: space-between
      padding: 3rem
      background: lighten($black, 10%)
      border-radius: 0 $global-border-radius $global-border-radius 0
      p, h2, a
        color: $white
      p
        margin-top: 2rem
      .phone
        margin-bottom: 2rem
        width: fit-content
      a:not(.phone)
        margin-top: 2rem
        text-align: center
    @include breakpoint(small down)
      flex-direction: column
      height: fit-content
      .agency-map-container, .agency-contact
        width: auto
      .agency-map-container
        min-height: 200px
      .agency-contact
        border-radius: 0 0 $global-border-radius $global-border-radius

  #agency-contact-form
    padding: 3rem 0
    background: $colored-bg
    h2, label, label span
      color: $white !important
    h2
      text-align: center
      margin-bottom: 2rem
    // textarea
    //   min-height: 5rem

  .department-name
    text-transform: capitalize
    color: currentcolor
  .widget .right
      position: fixed
      top: 50px
      left: 50px
  .show-room
    margin: 3rem 0
    h2
      text-align: center
      margin-bottom: 2rem
    img
      border-radius: $global-border-radius
      height: 400px
    @include breakpoint(small down)
      img
        margin-bottom: 1rem

  @media (max-width: 768px)
    .firstWidget
      display: none !important
</style>
