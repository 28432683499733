import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère une liste PostalCode
 * @param {string} search: paramètre de recherche, sur le code postal
 */
const getAll = async (search) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/postal-codes/`, {
      params: { ...(search && { search }) },
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const postalCodes = {};
postalCodes.getAll = getAll;

export default postalCodes;
