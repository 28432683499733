<template>
  <div class="agency-map">
    <div class="map-container">
      <div class="map-loader" v-if="mapIsLoading">
        <app-spinner />
        <p>Chargement de la carte...</p>
      </div>
      <div :class="{'map-hidden': mapIsLoading}" id="map"/>
    </div>
  </div>
</template>

<script>
import postalCodeApi from '@/services/api/postal-code';
import mapboxgl from 'mapbox-gl';
import mapboxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import * as turf from '@turf/turf';

export default {
  name: 'agency-map',

  props: {
    address: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      map: null,
      mapIsLoading: true,
      initialMapSettings: {
        center: [2.7195924, 46.9259861],
        zoom: 4.5,
      },
      borders: {
        type: 'FeatureCollection',
        name: 'borders',
        features: [],
      },
      geocodingClient: mapboxGeocoding({ accessToken: process.env.VUE_APP_MAPBOX_API_KEY }),
      location: null,
    };
  },

  async mounted() {
    await this.getLocation();
    this.setMap();
  },

  methods: {
    // Récupère les borders de la ville si la recherche de la localisation ne fonctionne pas
    async getPostalCode() {
      try {
        const response = await postalCodeApi.getAll(this.address.postalCode);
        this.borders.features = [response[0].borders];
      } catch (error) {
        this.$message.show({ title: 'Error', description: error.data.message });
      }
    },
    // Récupère la localisation
    async getLocation() {
      const locationResponse = await this.geocodingClient.forwardGeocode({
        query: `${this.address.addressLine1} ${this.address.city} ${this.address.postalCode}`,
        countries: ['FR'],
        limit: 1,
      }).send();

      [this.location] = locationResponse.body.features;
    },
    // Setup du marker
    setLocationMarker() {
      new mapboxgl.Marker().setLngLat(this.location.center).addTo(this.map);
      this.map.setMaxZoom(15);
      this.map.flyTo({
        center: this.location.center,
        zoom: 10,
        speed: 2,
        essential: true,
      });
    },
    // Setup de la carte
    setMap() {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_API_KEY;
      this.map = new mapboxgl.Map({
        container: 'map',
        style: process.env.VUE_APP_MAPBOX_STYLE_URL,
        center: this.initialMapSettings.center,
        zoom: this.initialMapSettings.zoom,
        minZoom: 0,
        maxZoom: 10,
        interactive: false,
      });

      // ajoute le marker ou un layer sur la commune selon le résultat de la recherche
      this.map.on('load', async () => {
        if (this.location) {
          this.setLocationMarker();
        } else {
          await this.getPostalCode();
          this.setLayers();
        }
        this.mapIsLoading = false;
      });
    },

    // Création des layers pour chaque commune
    setLayers() {
      const mapSource = this.map.getSource('borders');

      if (!mapSource) {
        // Ajout des sources
        this.map.addSource('borders', {
          type: 'geojson',
          data: this.borders,
        });

        // Création du layer de la surface pour chaque commune
        this.map.addLayer({
          id: 'borders-area',
          type: 'fill',
          source: 'borders',
          layout: {},
          paint: {
            'fill-color': '#088',
            'fill-opacity': 0.6,
          },
        });

        // Création du layer des frontières pour chaque commune
        this.map.addLayer({
          id: 'borders-borders',
          type: 'fill',
          source: 'borders',
          layout: {},
          paint: {
            'fill-outline-color': '#088',
            'fill-color': 'transparent',
            'fill-opacity': 1,
          },
        });
      } else {
        mapSource.setData({
          type: 'FeatureCollection',
          features: this.borders.features,
        });
      }

      // On centre la carte sur l'ensemble des borders
      if (this.borders.features.length > 0) {
        const coordinates = [];

        this.borders.features.forEach((ft) => {
          coordinates.push(...ft.geometry.coordinates[0]);
        });

        const line = turf.lineString(coordinates);
        const bbox = turf.bbox(line);

        this.map.fitBounds(bbox, { padding: 10 });
      } else {
        this.map.flyTo({
          center: this.initialMapSettings.center,
          zoom: this.initialMapSettings.zoom,
          speed: 2,
          essential: true,
        });
      }
    },
  },
};
</script>

<style lang="sass">
.agency-map
  height: 100%
  overflow: hidden
  .map-container
    position: relative
    display: flex
    align-items: center
    height: 100%
  .map-loader
    margin: auto
    p
      padding-top: 20px
      color: $info
  .map-hidden
    visibility: hidden

  #map
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    canvas
      width: 100%
      border-top-right-radius: $global-border-radius
      border-bottom-right-radius: $global-border-radius
    .mapboxgl-control-container
      display: none

    @include breakpoint(small down)
      min-height: 200px
      canvas
        border-bottom-right-radius: 0
        border-top-left-radius: $global-border-radius

</style>
